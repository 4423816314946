import React, { Fragment } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout/layout";
import { graphql, Link } from "gatsby";
import Fold from "../components/Fold/Fold";
import Content from "../components/Content/Content";
import { Heading } from "../components/Heading/Heading";
import Obfuscate from "react-obfuscate";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Impressum = () => {
  const { t } = useTranslation();

  return (
    <Layout
      pageTitle={t("impressum.title")}
      description={t("meta.imprint.description")}
      narrowLayout
    >
      <Fold title={t("footer.imprint")} centered></Fold>
      <Content>
        <Heading level="h2" smallDecorationDown>
          ICQ-GROUP AG
        </Heading>
        <ul className="list--no-bullets">
          <li>Beethovenstrasse 11</li>
          <li>8002 Zurich</li>
          <li>{t("impressum.switzerland")}</li>
        </ul>
        <p className="phone">
          {t("impressum.phone")}:
          <a href="tel:+41445009090" className="link"> +41 44 500 90 90</a>
        </p>
        <p>UID: CHE-218.193.603</p>

        <Heading level="h2" smallDecorationDown>
          {t("impressum.website-creation.title")}
        </Heading>
        <ul className="list--no-bullets">
          <li>UP Management</li>
          <li>Talstrasse 23</li>
          <li>6403 Küssnacht am Rigi</li>
          <li>{t("impressum.switzerland")}</li>
        </ul>
        <p className="phone">
          {t("impressum.tel")}:
          <a href="tel:+41795008336" className="link">+41 79 500 8336</a>
        </p>
        <p>
          {t("impressum.email")}:  <Obfuscate className="link" email="uwe@upmanagement.ch" />
        </p>

        <Heading level="h2" smallDecorationDown>
          {t("impressum.disclaimer-of-liability.title")}
        </Heading>

        <Heading level="h3" simple>
          {t("impressum.disclaimer-of-liability.contents.title")}
        </Heading>
        <p> {t("impressum.disclaimer-of-liability.contents.text")}</p>
        <Heading level="h3" simple>
          {t("impressum.disclaimer-of-liability.links.title")}
        </Heading>
        <p> {t("impressum.disclaimer-of-liability.links.text")}</p>
        <Heading level="h3" simple>
          {t("impressum.disclaimer-of-liability.copyright-law.title")}
        </Heading>
        <p> {t("impressum.disclaimer-of-liability.copyright-law.text")}</p>

        <Heading level="h2" smallDecorationDown>
          {t("impressum.data-protection.title")}
        </Heading>
        <p> {t("impressum.data-protection.text1")}</p>
        <p> {t("impressum.data-protection.click-here")}: <Link to="/privacy-policy" className="link">{t("impressum.data-protection.privacy-policy")}</Link></p>
        <p> {t("impressum.data-protection.text2")}</p>
      </Content>
    </Layout>
  );
};

export default Impressum;
